<template>
  <section id="b-wrapper" class="b-wrapper" :class="utilityClass">
    <fade-transition mode="out-in" :duration="250">
      <loading-base v-if="isLoadingProp" class="l-loading-overlay" />
    </fade-transition>

    <slot name="header">
      <header-layout
        :evaluation-client-list="evaluationClientList"
        :evaluation-types="evaluationTypes"
        :evaluation-is-loading="evaluationIsLoading"
        :iconname-prop="iconnameProp"
        :title-prop="titleProp"
        :backlink-prop="backlinkProp"
        :is-header-form-open="isHeaderFormOpen"
        :uses-open-header-form-button="usesOpenHeaderFormButton"
        :client-uuid="clientUuid"
        @set-header-form-open="setHeaderFormOpen"
        @set-header-form-closed="setHeaderFormClosed"
      />
    </slot>

    <main class="b-main">
      <slot name="main" />

      <section
        v-if="$slots.mainContent || $slots.mainContentInner"
        ref="mainContainer"
        class="b-section b-section--main-content"
        :class="[
          displayFilterProp === false ? '' : 'padding-top-3'
        ]"
      >
        <fade-transition mode="out-in" :duration="250">
          <loading-base
            v-if="contentIsLoadingProp"
            class="b-section--main-content__loading b-section--main-content__loading--content"
          />

          <template v-else>
            <slot name="mainContent" />

            <div v-if="$slots.mainContentInner" class="b-section--main-content__inner">
              <slot name="mainContentInner" />
            </div>
          </template>
        </fade-transition>
      </section>

      <section v-if="$slots.bottomFixed" class="b-section b-section--bottom-fixed">
        <slot name="bottomFixed" />
      </section>

      <slot name="mainBottom" />
    </main>
  </section>
</template>

<script>
import LoadingBase from '~/components/_base/Loading'
import FadeTransition from '~/components/_transitions/Fade'
import HeaderLayout from '~/components/_layouts/Header'

export default {
  components: {
    LoadingBase,
    FadeTransition,
    HeaderLayout
  },
  props: {
    isLoadingProp: {
      type: Boolean,
      default() {
        return false
      }
    },
    isHeaderFormOpenToggle: {
      type: Boolean,
      default() {
        return false
      }
    },
    usesOpenHeaderFormButton: {
      type: Boolean,
      default() {
        return false
      }
    },
    contentIsLoadingProp: {
      type: Boolean,
      default() {
        return false
      }
    },
    evaluationClientList: {
      type: Array,
      default() {
        return []
      }
    },
    evaluationTypes: {
      type: Array,
      default() {
        return []
      }
    },
    evaluationIsLoading: {
      type: Boolean,
      default() {
        return true
      }
    },
    iconnameProp: {
      type: String,
      default() {
        return ''
      }
    },
    titleProp: {
      type: String,
      default() {
        return ''
      }
    },
    backlinkProp: {
      type: String,
      default() {
        return ''
      }
    },
    clientUuid: {
      type: String,
      default() {
        return null
      }
    },
    displayFilterProp: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      isHeaderFormOpen: this.isHeaderFormOpenToggle !== false,
      resizeTimer: undefined
    }
  },
  computed: {
    utilityClass() {
      return this.isHeaderFormOpen ? 'b-wrapper--large-header' : 'b-wrapper--small-header'
    }
  },
  mounted() {
    if (process.browser) {
      // remove freeze on load
      if (document.body.classList.contains('freeze')) {
        setTimeout(() => {
          document.body.classList.remove('freeze')
        }, 250)
      }

      // during resize event - add freeze class
      // after resize - remove the freeze class
      window.addEventListener('resize', this.resizeMethod, {
        capture: true,
        passive: true
      })
    }
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener('resize', this.resizeMethod, {
        capture: true,
        passive: true
      })
    }
  },
  methods: {
    resizeMethod() {
      document.body.classList.add('freeze')

      clearTimeout(this.resizeTimer)

      this.resizeTimer = setTimeout(() => {
        document.body.classList.remove('freeze')
      }, 250)
    },
    setHeaderFormOpen() {
      this.isHeaderFormOpen = true
    },
    setHeaderFormClosed() {
      this.isHeaderFormOpen = false
    }
  }
}
</script>

<style>

.padding-top-3
{
  padding-top: 3rem;
}

</style>
