<template>
  <div
    class="b-icon"
    :class="[
      'b-icon--size-' + iconDetails.size,
      'b-icon--color-' + iconDetails.color,
      iconDetails.class
    ]"
  >
    <template>
      <component :is="comp" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    iconDetails: {
      type: Object,
      default() {
        return {
          name: 'Logo',
          size: '20',
          color: '',
          class: ''
        }
      }
    }
  },
  computed: {
    comp() {
      return () => import(`~/components/_svg/${this.iconDetails.name}.vue`)
    },
    componentName() {
      if (this.iconDetails.name !== null) {
        return this.iconDetails.name
      } else {
        return 'Logo'
      }
    }
  }
}
</script>

<style lang="scss">
.b-icon {
  display: block;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.b-icon--size {
  @for $i from 5 through 75 {
    $number: #{$i / 16}rem;
    &-#{$i} {
      height: $number;
      width: $number;
    }
  }
}

.b-icon--color {
  &-1 {
    &,
    .fill {
      fill: $col1;
    }
  }

  &-2 {
    &,
    .fill {
      fill: $col2;
    }
  }

  &-3 {
    &,
    .fill {
      fill: $col3;
    }
  }

  &-4 {
    &.fill {
      fill: $col4;
    }
  }
}
</style>
