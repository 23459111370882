<template>
  <div
    class="b-form__group"
    :class="[
      v.$error ? 'has-error' : '',
      (containerClassProp.length > 0) ? containerClassProp : ''
    ]"
  >
    <label
      v-if="labelProp.length > 0"
      class="b-form__label"
      :class="labelClassProp"
      :for="idProp"
    >{{ labelProp }}</label>

    <no-ssr>
      <v-date-picker
        v-model="inputValue"
        locale="en-GB"
        :mode="modeProp"
        :input-props="{
          id: idProp,
          name: nameProp,
          placeholder: placeholderProp,
          class: classProp ? 'b-form__input ' + classProp : 'b-form__input',
          readonly: readOnly
        }"
        :min-date="minDateProp === true ? minDate : null"
        :popover="{
          placement: 'bottom',
          visibility: 'click',
          positionFixed: true
        }"
        color="teal"
      />
    </no-ssr>

    <div class="b-form__message">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Date, String],
      default() {
        return new Date()
      }
    },
    v: {
      type: Object,
      required: true
    },
    containerClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelProp: {
      type: String,
      default() {
        return ''
      }
    },
    modeProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelClassProp: {
      type: String,
      default() {
        return null
      }
    },
    classProp: {
      type: String,
      default() {
        return null
      }
    },
    idProp: {
      type: String,
      default() {
        return null
      }
    },
    nameProp: {
      type: String,
      default() {
        return null
      }
    },
    placeholderProp: {
      type: String,
      default() {
        return null
      }
    },
    minDateProp: {
      type: Boolean,
      default() {
        return true
      }
    },
    readOnly: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      minDate: new Date()
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
