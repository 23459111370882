<template>
  <div
    class="b-form__group"
    :class="[
      v.$error ? 'has-error' : '',
      (containerClassProp.length > 0) ? containerClassProp : '',
    ]"
  >
    <label
      v-if="labelProp.length > 0"
      class="b-form__label"
      :class="labelClassProp"
      :for="idProp"
    >{{ labelProp }}</label>

    <div
      class="b-select"
      :class="[
        (selectContainerClassProp.length > 0) ? selectContainerClassProp : '',
      ]"
      :style="( disabled ) ? 'opacity: .7;': ''"
    >
      <div class="b-select__arrow">
        <icon-base
          :icon-details="{
            name: 'ChevDown',
            size: '17',
            color: '',
            class: 'b-select__arrow-icon'
          }"
        />
      </div>

      <div class="b-select__display">{{ displayText }}</div>

      <!-- :value="valueProp" -->
      <!--        v-model="valueProp"-->
      <select
        :id="idProp"
        class="b-select__select"
        :class="classProp"
        :name="nameProp"
        :disabled="disabled"
        @input="triggerSelectChange"
      >
        <!--<option value="__initial__" disabled selected>{{ displayText }}</option>-->
        <option
          v-for="(item, index) in optionProp"
          :key="index"
          :value="item.code"
          :selected="item.label === valueProp"
        >{{ item.label }}</option>
      </select>
    </div>

    <div class="b-form__message">
      <slot />
    </div>
  </div>
</template>

<script>
import IconBase from '~/components/_base/Icon'

export default {
  components: {
    IconBase
  },
  props: {
    v: {
      type: Object,
      default() {
        return {
          $error: false
        }
      }
    },
    containerClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    selectContainerClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelClassProp: {
      type: String,
      default() {
        return null
      }
    },
    hideLabelProp: {
      type: Boolean,
      default() {
        return false
      }
    },
    idProp: {
      type: String,
      default() {
        return null
      }
    },
    classProp: {
      type: String,
      default() {
        return null
      }
    },
    nameProp: {
      type: String,
      default() {
        return null
      }
    },
    optionProp: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    valueProp: {
      type: String,
      default() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      selectionText: null
    }
  },
  computed: {
    displayText: {
      get() {

        if (this.selectionText !== null) {
          return this.selectionText;
        }

        const label = this.optionProp[0] ? this.optionProp[0].label : '';

        return (this.valueProp && this.valueProp.length) ? this.valueProp : label
      },
      set(value) {
        this.selectionText = value;
      }
    }
  },
  methods: {

    triggerSelectChange(event) {
      // find index of select
      const INDEX_CONST = this.optionProp.findIndex((i) => i.code === event.target.value)

      if (INDEX_CONST > -1) {
        // fill display text with value
        this.displayText = this.optionProp[INDEX_CONST].label

        // emit the select data as an object
        this.$emit('select-change-emit', {
          code: event.target.value,
          label: this.optionProp[INDEX_CONST].label
        })
      }
    }
  }
}
</script>
