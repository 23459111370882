<template>
  <div class="b-loading">
    <div class="b-loading__container">
      <icon-base
        class="b-loading__icon"
        :icon-details="{
          name: 'Loading',
          size: '30',
          color: '',
          class: ''
        }"
      />
      <p class="b-loading__text">Loading...</p>
    </div>
  </div>
</template>

<script>
import IconBase from '~/components/_base/Icon'

export default {
  components: {
    IconBase
  }
}
</script>

<style lang="scss">
.b-loading {
  position: absolute;
  z-index: z-index('default');
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.b-loading__container {
  position: absolute;
  z-index: z-index('one');
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.b-loading__icon {
  margin: 0 auto;
  animation: fullrotation 720ms infinite linear;

  .fill {
    fill: $col1;
  }
}

.b-loading__text {
  @extend %font-size--medium;
  color: $col1;
  margin: px-to-rem(5px) 0 0;
}

// green variation
.b-loading--green {
  background: $col1;

  .b-loading__icon {
    .fill {
      fill: $white-colour;
    }
  }

  .b-loading__text {
    color: $white-colour;
  }
}

.b-loading--light-grey {
  background: $lightest-grey-colour;
}
</style>
