<template>
  <div class="l-header__right l-header__right--button-container">
    <button
      class="b-button b-button--base-trans b-button--icon b-button--icon--right l-header__button"
      :disabled="isWaiting || isBusy"
      @click="buildPdfMethod"
    >
      {{ isWaiting ? 'Waiting for jobs to finish' : isBusy ? 'Building': 'Build PDF' }}
      <icon-base
        :icon-details="{
          name: 'Plus',
          size: '20',
          color: '',
          class: 'l-header__button-icon'
        }"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconBase from '~/components/_base/Icon'

export default {
  components: {
    IconBase
  },
  data(){
    return {
      isBusy: false,
      isWaiting: false,
      incompletesPollId: null,
    }
  },
  computed: {
    ...mapGetters({
      incompletes: 'evaluations/getIncompletes',
    })
  },
  mounted() {
    this.checkIncompleteJobs()
  },
  methods: {
    buildPdfMethod() {
      this.isBusy = true

      this.$store.dispatch('report/store', {
        evaluation: this.$route.params.id
      }).then( (response) => {

      }).finally(()=>{
        this.isBusy = false
      })


    },
    checkIncompleteJobs()
    {
      if ( this.incompletes !== null && this.incompletes.length > 0 ) {

        this.isWaiting = true;

        this.incompletesPollId = setInterval(() => {
          this.$router.go();
        }, 7500)

      }else{
        clearInterval( this.incompletesPollId )
      }
    }
  }
}
</script>
