var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-form__group",class:[
    _vm.v.$error ? 'has-error' : '',
    (_vm.containerClassProp.length > 0) ? _vm.containerClassProp : '' ]},[(_vm.labelProp.length > 0)?_c('label',{staticClass:"b-form__label",class:_vm.labelClassProp,attrs:{"for":_vm.idProp}},[_vm._v(_vm._s(_vm.labelProp))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"b-select",class:[
      (_vm.selectContainerClassProp.length > 0) ? _vm.selectContainerClassProp : '' ],style:(( _vm.disabled ) ? 'opacity: .7;': '')},[_c('div',{staticClass:"b-select__arrow"},[_c('icon-base',{attrs:{"icon-details":{
          name: 'ChevDown',
          size: '17',
          color: '',
          class: 'b-select__arrow-icon'
        }}})],1),_vm._v(" "),_c('div',{staticClass:"b-select__display"},[_vm._v(_vm._s(_vm.displayText))]),_vm._v(" "),_c('select',{staticClass:"b-select__select",class:_vm.classProp,attrs:{"id":_vm.idProp,"name":_vm.nameProp,"disabled":_vm.disabled},on:{"input":_vm.triggerSelectChange}},_vm._l((_vm.optionProp),function(item,index){return _c('option',{key:index,domProps:{"value":item.code,"selected":item.label === _vm.valueProp}},[_vm._v(_vm._s(item.label))])}),0)]),_vm._v(" "),_c('div',{staticClass:"b-form__message"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }