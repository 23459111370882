<template>
  <div class="l-header__right l-header__form">
    <fade-transition mode="out-in" :duration="250">
      <loading-base v-if="evaluationIsLoading || isBusy" class="b-loading--green l-header__form-loading" />
    </fade-transition>

    <form
      class="b-form l-header__form-form"
      :class="{ 'l-header__form-form--visible': evaluationIsLoading === false || isBusy === false }"
      novalidate
      @submit.prevent="startNewInspection"
    >
      <select-base
        :searchable-prop="false"
        container-class-prop="l-header__form-group l-header__form-dropdown"
        select-container-class-prop="b-select--white b-select--dropdown-block b-select--no-border-radius"
        :value-prop="formData.client"
        :v="$v.formData.client"
        :option-prop="evaluationClientList"
        @select-change-emit="setTeam"
      >
        <template v-if="$v.formData.client.$dirty">
          <span
            v-if="!$v.formData.client.ecDoesntContain || !$v.formData.client.required"
          >The client field is required</span>
        </template>
      </select-base>

      <select-base
        :searchable-prop="false"
        container-class-prop="l-header__form-group l-header__form-dropdown"
        select-container-class-prop="b-select--white b-select--dropdown-block b-select--no-border-radius"
        :value-prop="formData.site"
        :v="$v.formData.site"
        :option-prop="siteOptions"
        @select-change-emit="setSite"
      >
        <template v-if="$v.formData.site.$dirty">
          <span v-if="!$v.formData.site.esDoesntContain || !$v.formData.site.required">The site field is required</span>
        </template>
      </select-base>

      <select-base
        :searchable-prop="false"
        container-class-prop="l-header__form-group l-header__form-dropdown"
        select-container-class-prop="b-select--white b-select--dropdown-block b-select--no-border-radius"
        :value-prop="formData.evaluationTypes"
        :v="$v.formData.evaluationTypes"
        :option-prop="evaluationTypeOptions"
        @select-change-emit="setEvaluationType"
      >
        <template v-if="$v.formData.evaluationTypes.$dirty">
          <span v-if="!$v.formData.evaluationTypes.etDoesntContain">The inspection type field is required</span>
        </template>
      </select-base>

      <date-input-base
        v-model="formData.date"
        :v="$v.formData.date"
        :min-date-prop="false"
        id-prop="l-header__form-date-input"
        class-prop="b-form__input--border-white l-header__form-date-input"
        container-class-prop="l-header__form-group"
      >
        <template v-if="$v.formData.date.$dirty">
          <div class="b-form__message">
            <span v-if="!$v.formData.date.required">The date field is required</span>
          </div>
        </template>
      </date-input-base>

      <div class="u-no-margin b-form__group l-header__form-group">
        <button
          class="b-button b-button--base-trans b-button--icon b-button--icon--right l-header__button"
          :disabled="isBusy"
          type="submit"
        >
          {{ isBusy ? 'Please wait' : submitLabel }}
          <icon-base
            :icon-details="{
              name: 'ChevRight',
              size: '20',
              color: '',
              class: 'l-header__button-icon',
            }"
          />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import LoadingBase from '~/components/_base/Loading'
import SelectBase from '~/components/_base/form/Select'
import DateInputBase from '~/components/_base/form/Date'
import IconBase from '~/components/_base/Icon'
import FadeTransition from '~/components/_transitions/Fade'

export default {
  components: {
    LoadingBase,
    SelectBase,
    DateInputBase,
    IconBase,
    FadeTransition,
  },
  props: {
    evaluationClientList: {
      type: Array,
      default() {
        return []
      },
    },
    evaluationTypes: {
      type: Array,
      default() {
        return []
      },
    },
    evaluationIsLoading: {
      type: Boolean,
      default() {
        return true
      },
    },
    submitLabel: {
      type: String,
      default() {
        return 'Start Inspection'
      },
    },
  },
  data() {
    return {
      evaluationTypesData: [
        {
          label: 'Product type',
          code: '',
        },
      ],
      chosenTeam: null,
      isBusy: false,
      formData: {
        client: 'Client',
        clientData: '',
        site: 'Site',
        siteData: '',
        evaluationTypes: 'Product type',
        evaluationTypesData: '',
        date: new Date(),
      },
    }
  },
  validations: {
    formData: {
      client: {
        ecDoesntContain(value) {
          return value !== 'Client'
        },
        required,
      },
      site: {
        esDoesntContain(value) {
          return value !== 'Site'
        },
        required,
      },
      evaluationTypes: {
        etDoesntContain(value) {
          return value !== 'Product type'
        },
        required,
      },
      date: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      sites: 'site/getCollection',
      sitesLoading: 'site/getIsLoading',
      // evaluationTypes: 'evaluationTypes/getCollection',
      evaluationTypeNestedTeams: 'evaluationTypes/getNestedTeamsCollection',
    }),
    siteOptions() {
      let options = []
      if (this.sites !== null && this.sites !== undefined && this.sites.length > 0) {
        options = []

        options.push({
          label: 'Site',
          code: '',
        })

        this.sites.forEach((site) => {
          if (site.team_uuid === this.chosenTeam) {
            options.push({
              label: site.title,
              code: site.uuid,
            })
          }
        })
      }

      return options
    },
    evaluationTypeOptions() {
      let options = []
      if (this.evaluationTypes !== null && this.evaluationTypes !== undefined && this.evaluationTypes.length > 0) {
        options = []

        options.push({
          label: 'Type',
          code: '',
        })

        // if (this.$route.name === 'inspections') {
        this.evaluationTypes.forEach((evaluationType) => {
          if (
            this.evaluationTypeNestedTeams[evaluationType.uuid] !== undefined &&
            this.evaluationTypeNestedTeams[evaluationType.uuid] !== null
          ) {
            if (
              // this.$route.name === 'inspections' &&
              this.evaluationTypeNestedTeams[evaluationType.uuid].includes(this.chosenTeam)
            ) {
              options.push({
                label: evaluationType.title,
                code: evaluationType.uuid,
              })
            }
          }
        })
      }
      // if (this.$route.name === 'assessments') {
      //   this.evaluationTypes.forEach((evaluationType) => {
      //     options.push({
      //       label: evaluationType.title,
      //       code: evaluationType.uuid,
      //     })
      //   })
      // }
      // }
      return options
    },
  },

  mounted() {
    if (this.sites === null || this.sites === undefined || this.sites.length === 0) {
      this.$store.dispatch('site/collection')
    }
  },
  methods: {
    setTeam(data) {
      this.chosenTeam = data.code
      // clear out form data for the sites drop down
      if (this.formData.site !== 'Site' || this.formData.siteData !== '') {
        this.formData.site = 'Site'
        this.formData.siteData = ''
      }

      // get the sites for this team
      const params = {
        team: this.chosenTeam,
        pageSize: 1000,
      }

      this.$store.dispatch('site/collection', params)

      // set form data for the clients drop down
      this.formData.client = data.label
      this.formData.clientData = data.code
    },
    setSite(data) {
      this.formData.site = data.label
      this.formData.siteData = data.code
    },
    setEvaluationType(data) {
      this.formData.evaluationTypes = data.label
      this.formData.evaluationTypesData = data.code
    },
    // async createAssessment() {
    async startNewInspection() {
      // validate
      this.$v.formData.$touch()

      // if its still pending or an error is returned do not submit
      if (this.$v.formData.$pending || this.$v.formData.$error) return

      this.isBusy = true

      const FORM_DATA_CONST = new FormData()
      FORM_DATA_CONST.append('site', this.formData.siteData)
      FORM_DATA_CONST.append('evaluation_type', this.formData.evaluationTypesData)
      const FORMATTED_DATE_CONST = moment(this.formData.date).format('YYYY-MM-DD')
      FORM_DATA_CONST.append('start_date', FORMATTED_DATE_CONST)

      if (this.$route.name === 'assessments') {
        const ADD_YEAR = moment(this.formData.date.setFullYear(this.formData.date.getFullYear() + 1)).format(
          'DD-MM-YYYY'
        )
        FORM_DATA_CONST.append('next_dated_at', ADD_YEAR)
      }

      // if (this.$route.name === 'assessments') {
      // }

      this.$store.dispatch('contacts/clearStore')
      this.$store.dispatch('evaluationCategories/clearStore')
      this.$store.dispatch('evaluationQuestionSections/clearStore')
      this.$store.dispatch('evaluationQuestionAnswers/clearStore')
      this.$store.dispatch('evaluationPrompts/clearStore')
      this.$store.dispatch('answers/clearStore')
      this.$store.dispatch('answerActions/clearStore')
      this.$store.dispatch('answerAssets/clearStore')
      this.$store.dispatch('signOffs/clearStore')

      await this.$store.dispatch('evaluations/store', FORM_DATA_CONST).then((response) => {
        this.$store.dispatch('evaluations/setInstanceFromCollectionByUuid', { uuid: response.data.data.uuid })

        this.$router.push({
          path: '/evaluations/' + response.data.data.uuid,
        })
      })
    },
  },
}
</script>

<style lang="scss">
.l-header__form-loading {
  z-index: z-index('ten');
}

.l-header__form-form {
  position: relative;
  z-index: z-index('default');
  min-height: px-to-rem(130px);
  display: block;
  opacity: 0;

  &--visible {
    opacity: 1;
  }
}

.l-header__form-group {
  margin-bottom: px-to-rem(7px);

  .b-form__message {
    position: relative;
  }
}

.l-header__form-dropdown {
  .b-select__display {
    font-size: px-to-rem(15px);
    font-weight: $bold;
  }

  &:not(.is-error) .b-select__display {
    color: $col4;
  }

  .b-select__arrow {
    right: px-to-rem(3px);

    .b-icon {
      height: px-to-rem(14px);
      width: px-to-rem(14px);
    }
  }
}

.l-header__form-date-input {
  font-weight: $bold;
  text-transform: uppercase;
}
</style>
