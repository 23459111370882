<template>
  <a
    v-if="useNormalLink"
    :id="idProp"
    class="b-link"
    :href="hrefProp"
    :class="classProp"
    :aria-label="ariaLabelProp"
    :target="targetProp"
    :data-target="dataTargetProp"
    :rel="relProp"
    :title="titleProp"
    :role="roleProp"
  >
    <icon-base
      v-if="icon === true && iconIndicator === false"
      class="b-link__icon"
      :icon-details="iconDetails"
    />

    <icon-indicator-base
      v-else-if="icon === true && iconIndicator === true"
      class="b-link__icon"
      :indicator-class-prop="iconIndicatorVisible === false ? ' b-icon-indicator__indicator--hidden' : ''"
      :icon-object-prop="iconDetails"
    >{{ iconCounter }}</icon-indicator-base>

    <span v-if="typeof linkText !== 'object'" class="b-link__text">{{ linkText }}</span>

    <slot name="default-link-slot" />
  </a>

  <nuxt-link
    v-else
    :id="idProp"
    :to="hrefProp"
    :aria-label="ariaLabelProp"
    :class="classProp"
    :rel="relProp"
    :title="titleProp"
    :role="roleProp"
    :target="targetProp"
  >
    <icon-base
      v-if="icon === true && iconIndicator === false"
      class="b-link__icon"
      :icon-details="iconDetails"
    />

    <icon-indicator-base
      v-else-if="icon === true && iconIndicator === true"
      class="b-link__icon"
      :indicator-class-prop="iconIndicatorVisible === false ? ' b-icon-indicator__indicator--hidden' : ''"
      :icon-object-prop="iconDetails"
    >{{ iconCounter }}</icon-indicator-base>

    <span v-if="typeof linkText !== 'object'" :class="spanClass">{{ linkText }}</span>

    <slot name="default-link-slot" />
  </nuxt-link>
</template>

<script>
import IconBase from '~/components/_base/Icon'
import IconIndicatorBase from '~/components/_base/IconIndicator'

export default {
  components: {
    IconBase,
    IconIndicatorBase
  },
  props: {
    useNormalLink: {
      type: Boolean,
      default() {
        return false
      }
    },
    hrefProp: {
      type: String,
      default() {
        return null
      }
    },
    idProp: {
      type: String,
      default() {
        return null
      }
    },
    classProp: {
      type: String,
      default() {
        return null
      }
    },
    targetProp: {
      type: String,
      default() {
        return null
      }
    },
    dataTargetProp: {
      type: String,
      default() {
        return null
      }
    },
    relProp: {
      type: String,
      default() {
        return null
      }
    },
    titleProp: {
      type: String,
      default() {
        return null
      }
    },
    roleProp: {
      type: String,
      default() {
        return null
      }
    },
    linkText: {
      type: String,
      default() {
        return null
      }
    },
    spanClass: {
      type: String,
      default() {
        return "b-link__text"
      }
    },
    ariaLabelProp: {
      type: String,
      default() {
        return null
      }
    },
    icon: {
      type: Boolean,
      default() {
        return false
      }
    },
    iconIndicator: {
      type: Boolean,
      default() {
        return false
      }
    },
    iconIndicatorVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    iconDetails: {
      type: Object,
      default() {
        return {
          name: 'Logo',
          size: '20',
          color: '',
          class: ''
        }
      }
    },
    iconCounter: {
      type: Number,
      default() {
        return 0
      }
    }
  }
}
</script>
