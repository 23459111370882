var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-form__group",class:[
    _vm.v.$error ? 'has-error' : '',
    (_vm.containerClassProp.length > 0) ? _vm.containerClassProp : ''
  ]},[(_vm.labelProp.length > 0)?_c('label',{staticClass:"b-form__label",class:_vm.labelClassProp,attrs:{"for":_vm.idProp}},[_vm._v(_vm._s(_vm.labelProp))]):_vm._e(),_vm._v(" "),_c('no-ssr',[_c('v-date-picker',{attrs:{"locale":"en-GB","mode":_vm.modeProp,"input-props":{
        id: _vm.idProp,
        name: _vm.nameProp,
        placeholder: _vm.placeholderProp,
        class: _vm.classProp ? 'b-form__input ' + _vm.classProp : 'b-form__input',
        readonly: _vm.readOnly
      },"min-date":_vm.minDateProp === true ? _vm.minDate : null,"popover":{
        placement: 'bottom',
        visibility: 'click',
        positionFixed: true
      },"color":"teal"},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1),_vm._v(" "),_c('div',{staticClass:"b-form__message"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }