import LazyLoad from 'vanilla-lazyload'

const LazyLoadInit = {
  init() {
    // eslint-disable-next-line no-unused-vars
    const LAZYLOAD_INSTANCE = new LazyLoad({
      elements_selector: '.b-lazyload',
      threshold: 300,
      class_loading: 'b-lazyload--loading',
      class_loaded: 'b-lazyload--loaded',
      class_error: 'b-lazyload--error'
    })
  }
}

export default LazyLoadInit
