<template>
  <div class="l-header__right l-header__right--button-container">
    <link-base
      class-prop="b-button b-button--base-trans b-button--icon b-button--icon--right l-header__button"
      :href-prop="'/clients/' + $route.params.id + '/members/add'"
    >
      <template slot="default-link-slot">
        Add team member
        <icon-base
          :icon-details="{
            name: 'Plus',
            size: '20',
            color: '',
            class: 'l-header__button-icon'
          }"
        />
      </template>
    </link-base>
  </div>
</template>

<script>
import IconBase from '~/components/_base/Icon'
import LinkBase from '~/components/_base/Link'

export default {
  components: {
    IconBase,
    LinkBase
  }
}
</script>
