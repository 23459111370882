var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"l-header"},[(_vm.showTestBanner)?_c('div',{staticClass:"test-banner"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"l-header__container"},[_c('div',{staticClass:"l-header__inner-container l-header__inner-container--top"},[_c('link-base',{attrs:{"class-prop":"l-header__logo","icon":true,"icon-details":{
          name: 'Logo',
          size: '36',
          color: '',
          class: 'l-header__logo-icon'
        },"href-prop":"/"}}),_vm._v(" "),_c('nav',{staticClass:"b-nav b-nav--space-between l-header__nav"},[_c('ul',{staticClass:"b-nav__list",attrs:{"role":"menubar"}},[_c('li',{staticClass:"b-nav__list-element"},[_c('div',{staticClass:"b-nav__link l-header__nav-user"},[_c('a',{staticClass:"b-nav__link l-header__nav-user-image",attrs:{"href":'/admin/me'}},[_c('image-base',{attrs:{"id-prop":"userAvatar","lazyload-prop":true,"container-class-prop":"u-no-margin l-header__nav-user-image-lazyload-image","src-prop":_vm.userImage,"alt-prop":_vm.userName + '\'s Avatar'}}),_vm._v(" "),_c('fade-transition',{attrs:{"mode":"out-in","duration":250,"group":true}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$nuxt.isOffline),expression:"$nuxt.isOffline"}],key:_vm.userIconCount,staticClass:"l-header__nav-user-image-icon"},[_c('icon-base',{attrs:{"icon-details":{
                        name: 'Cloud',
                        size: '17',
                        color: '1',
                        class: ''
                      }}})],1)])],1),_vm._v(" "),_c('span',{staticClass:"l-header__nav-user-text l-header__mobile-hidden"},[_vm._v("Hi "+_vm._s(_vm.add3Dots(_vm.userName, 9)))])])]),_vm._v(" "),_c('li',{staticClass:"b-nav__list-element"},[_c('link-base',{attrs:{"class-prop":"b-nav__link l-header__nav-logout","icon":true,"icon-details":{
                name: 'Logout',
                size: '30',
                color: '',
                class: 'l-header__nav-logout-icon'
              },"span-class":"b-link__text l-header__mobile-hidden","href-prop":"/","link-text":"Logout"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.logoutMethod($event)}}})],1)])])],1),_vm._v(" "),_c('div',{staticClass:"l-header__inner-container l-header__inner-container--bottom"},[_c('div',{staticClass:"l-header__left l-header__title"},[(_vm.isHeaderFormOpen)?_c('icon-base',{attrs:{"icon-details":{
            name: _vm.iconnameProp.length > 0 ? _vm.iconnameProp : 'HeaderChecklist',
            size: '61',
            color: '',
            class: 'l-header__title-icon'
          }}}):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"u-no-margin",class:( _obj = {}, _obj['l-header__title-title_mobile'] = _vm.isMobile, _obj['l-header__title-title'] = !_vm.isMobile, _obj )},[(_vm.backlinkProp.length > 0)?_c('link-base',{attrs:{"class-prop":"l-header__title-title-backlink","href-prop":_vm.backlinkProp,"icon":true,"icon-details":{
              name: 'ChevLeft',
              size: '18',
              color: '',
              class: 'l-header__title-modalTitle-backlink-icon'
            }}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.titleProp.length > 0 ? _vm.titleProp : 'New Inspection')+"\n          \n          "),(_vm.$route.name === 'actions')?[(_vm.usesOpenHeaderFormButton && _vm.isHeaderFormOpen === false)?_c('a',{staticClass:"button--open-basic-actions",on:{"click":_vm.setHeaderFormOpen}},[_vm._v("+")]):_vm._e()]:[(_vm.usesOpenHeaderFormButton && _vm.isHeaderFormOpen === false)?_c('a',{staticClass:"button--open-basic",on:{"click":_vm.setHeaderFormOpen}},[_vm._v("+")]):_vm._e()],_vm._v(" "),(_vm.usesOpenHeaderFormButton && _vm.isHeaderFormOpen === true)?_c('a',{staticClass:"button--open-basic",on:{"click":_vm.setHeaderFormClosed}},[_vm._v("-")]):_vm._e()],2)],1),_vm._v(" "),_c('fade-transition',{attrs:{"mode":"out-in","duration":250}},[(_vm.$nuxt.isOnline && _vm.isHeaderFormOpen)?[(
              ['inspections', 'assessments'].includes(_vm.$route.name) && _vm.rolePermissions.includes('evaluation.create')
            )?_c('new-inspection-form',{attrs:{"evaluation-client-list":_vm.evaluationClientList,"evaluation-types":_vm.evaluationTypes,"evaluation-is-loading":_vm.evaluationIsLoading,"submit-label":_vm.$route.name === 'assessments' ? 'Start Assessment' : 'Start Inspection'}}):(_vm.$route.name === 'admin' && _vm.rolePermissions.includes('user.create'))?_c('add-user'):(_vm.$route.name === 'admin-inspection' && _vm.rolePermissions.includes('evaluation-type.create'))?_c('add-inspection-type'):(
              _vm.$route.name === 'admin-inspection-inspectionId-categories' &&
                _vm.rolePermissions.includes('evaluation-type-category.create')
            )?_c('add-inspection-category'):(
              _vm.$route.name === 'admin-inspection-inspectionId-categories-categoryId-prompts' &&
                _vm.rolePermissions.includes('evaluation-type-prompt.create')
            )?_c('add-inspection-prompt'):(
              _vm.$route.name === 'admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers' &&
                _vm.rolePermissions.includes('saved-answer.create')
            )?_c('add-answer'):(_vm.$route.name === 'clients-id-members' && _vm.rolePermissions.includes('user.create'))?_c('add-team-member'):(_vm.$route.name === 'clients' && _vm.rolePermissions.includes('team.create'))?_c('add-new-client'):(_vm.$route.name === 'clients-id-sites-siteId-contacts' && _vm.rolePermissions.includes('team.create'))?_c('add-new-contact'):(_vm.$route.name === 'clients-id-sites' && _vm.rolePermissions.includes('site.create'))?_c('add-new-site'):(
              _vm.$route.name === 'clients-id-notification-alerts' &&
                _vm.rolePermissions.includes('metric-notification-trigger.create')
            )?_c('add-new-notification-trigger',{attrs:{"client-uuid":_vm.clientUuid}}):(
              (_vm.$route.name === 'new-inspection-id-details' ||
                _vm.$route.name === 'evaluations-id-reports' ||
                _vm.$route.name === 'assessments-reports') &&
                _vm.rolePermissions.includes('distribution.create')
            )?_c('build-pdf'):_vm._e()]:_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }