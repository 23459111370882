<template>
  <header class="l-header">
    <div v-if="showTestBanner" class="test-banner"></div>

    <div class="l-header__container">
      <div class="l-header__inner-container l-header__inner-container--top">
        <link-base
          class-prop="l-header__logo"
          :icon="true"
          :icon-details="{
            name: 'Logo',
            size: '36',
            color: '',
            class: 'l-header__logo-icon'
          }"
          href-prop="/"
        />

        <nav class="b-nav b-nav--space-between l-header__nav">
          <ul class="b-nav__list" role="menubar">
            <li class="b-nav__list-element">
              <div class="b-nav__link l-header__nav-user">
                <a class="b-nav__link l-header__nav-user-image" :href="'/admin/me'">
                  <image-base
                    id-prop="userAvatar"
                    :lazyload-prop="true"
                    container-class-prop="u-no-margin l-header__nav-user-image-lazyload-image"
                    :src-prop="userImage"
                    :alt-prop="userName + '\'s Avatar'"
                  />

                  <fade-transition mode="out-in" :duration="250" :group="true">
                    <!-- Boolean needs to come from vuex data -->
                    <!-- Need multiple icons for different things -->
                    <div v-show="$nuxt.isOffline" :key="userIconCount" class="l-header__nav-user-image-icon">
                      <icon-base
                        :icon-details="{
                          name: 'Cloud',
                          size: '17',
                          color: '1',
                          class: ''
                        }"
                      />
                    </div>
                  </fade-transition>
                </a>

                <span class="l-header__nav-user-text l-header__mobile-hidden">Hi {{ add3Dots(userName, 9) }}</span>
              </div>
            </li>

            <li class="b-nav__list-element">
              <link-base
                class-prop="b-nav__link l-header__nav-logout"
                :icon="true"
                :icon-details="{
                  name: 'Logout',
                  size: '30',
                  color: '',
                  class: 'l-header__nav-logout-icon'
                }"
                span-class="b-link__text l-header__mobile-hidden"
                href-prop="/"
                link-text="Logout"
                @click.native.prevent="logoutMethod"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div class="l-header__inner-container l-header__inner-container--bottom">
        <div class="l-header__left l-header__title">
          <icon-base
            v-if="isHeaderFormOpen"
            :icon-details="{
              name: iconnameProp.length > 0 ? iconnameProp : 'HeaderChecklist',
              size: '61',
              color: '',
              class: 'l-header__title-icon'
            }"
          />

          <h1 class="u-no-margin" :class="{ ['l-header__title-title_mobile']: isMobile, ['l-header__title-title']: !isMobile }">
            <link-base
              v-if="backlinkProp.length > 0"
              class-prop="l-header__title-title-backlink"
              :href-prop="backlinkProp"
              :icon="true"
              :icon-details="{
                name: 'ChevLeft',
                size: '18',
                color: '',
                class: 'l-header__title-modalTitle-backlink-icon'
              }"
            />
            {{ titleProp.length > 0 ? titleProp : 'New Inspection' }}
            
            <template v-if="$route.name === 'actions'">
              <a
                v-if="usesOpenHeaderFormButton && isHeaderFormOpen === false"
                class="button--open-basic-actions"
                @click="setHeaderFormOpen"
              >+</a>
            </template>
            <template v-else>
              <a
                v-if="usesOpenHeaderFormButton && isHeaderFormOpen === false"
                class="button--open-basic"
                @click="setHeaderFormOpen"
              >+</a>
            </template>
            <!--<a
              v-if="usesOpenHeaderFormButton && isHeaderFormOpen === false"
              class="button--open-basic"
              @click="setHeaderFormOpen"
            >+</a>-->
            <a
              v-if="usesOpenHeaderFormButton && isHeaderFormOpen === true"
              class="button--open-basic"
              @click="setHeaderFormClosed"
            >-</a>
          </h1>
        </div>

        <!-- if you want elements to appear in the -->
        <!-- right of the header, add them here -->
        <fade-transition mode="out-in" :duration="250">
          <template v-if="$nuxt.isOnline && isHeaderFormOpen">
            <new-inspection-form
              v-if="
                ['inspections', 'assessments'].includes($route.name) && rolePermissions.includes('evaluation.create')
              "
              :evaluation-client-list="evaluationClientList"
              :evaluation-types="evaluationTypes"
              :evaluation-is-loading="evaluationIsLoading"
              :submit-label="$route.name === 'assessments' ? 'Start Assessment' : 'Start Inspection'"
            />

            <add-user v-else-if="$route.name === 'admin' && rolePermissions.includes('user.create')" />

            <add-inspection-type
              v-else-if="$route.name === 'admin-inspection' && rolePermissions.includes('evaluation-type.create')"
            />

            <add-inspection-category
              v-else-if="
                $route.name === 'admin-inspection-inspectionId-categories' &&
                  rolePermissions.includes('evaluation-type-category.create')
              "
            />

            <add-inspection-prompt
              v-else-if="
                $route.name === 'admin-inspection-inspectionId-categories-categoryId-prompts' &&
                  rolePermissions.includes('evaluation-type-prompt.create')
              "
            />

            <add-answer
              v-else-if="
                $route.name === 'admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers' &&
                  rolePermissions.includes('saved-answer.create')
              "
            />

            <add-team-member
              v-else-if="$route.name === 'clients-id-members' && rolePermissions.includes('user.create')"
            />

            <add-new-client v-else-if="$route.name === 'clients' && rolePermissions.includes('team.create')" />

            <add-new-contact
              v-else-if="$route.name === 'clients-id-sites-siteId-contacts' && rolePermissions.includes('team.create')"
            />

            <add-new-site v-else-if="$route.name === 'clients-id-sites' && rolePermissions.includes('site.create')" />

            <add-new-notification-trigger
              v-else-if="
                $route.name === 'clients-id-notification-alerts' &&
                  rolePermissions.includes('metric-notification-trigger.create')
              "
              :client-uuid="clientUuid"
            />

            <build-pdf
              v-else-if="
                ($route.name === 'new-inspection-id-details' ||
                  $route.name === 'evaluations-id-reports' ||
                  $route.name === 'assessments-reports') &&
                  rolePermissions.includes('distribution.create')
              "
            />
          </template>
        </fade-transition>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import AddTeamMember from './Header/AddTeamMember'
import ImageBase from '~/components/_base/Image'
import LinkBase from '~/components/_base/Link'
import IconBase from '~/components/_base/Icon'
import FadeTransition from '~/components/_transitions/Fade'
import NewInspectionForm from '~/components/_layouts/Header/NewInspectionForm'
import AddNewClient from '~/components/_layouts/Header/AddNewClient'
import AddUser from '~/components/_layouts/Header/AddUser'
import AddInspectionType from '~/components/_layouts/Header/AddInspectionType'
import AddInspectionCategory from '~/components/_layouts/Header/AddInspectionCategory'
import AddInspectionPrompt from '~/components/_layouts/Header/AddInspectionPrompt'
import AddAnswer from '~/components/_layouts/Header/AddAnswer'
import AddNewSite from '~/components/_layouts/Header/AddNewSite'
import BuildPdf from '~/components/_layouts/Header/BuildPdf'
import AddNewNotificationTrigger from '~/components/_layouts/Header/AddNewNotificationTrigger'
import AddNewContact from '~/components/_layouts/Header/AddNewContact.vue'

export default {
   layout: ({isMobile})=> isMobile ? 'mobile' : 'default',
  components: {
    ImageBase,
    LinkBase,
    IconBase,
    FadeTransition,
    NewInspectionForm,
    AddNewClient,
    AddUser,
    AddInspectionType,
    AddInspectionCategory,
    AddInspectionPrompt,
    AddAnswer,
    AddNewSite,
    AddTeamMember,
    BuildPdf,
    AddNewNotificationTrigger,
    AddNewContact
  },
  props: {
    isHeaderFormOpen: {
      type: Boolean,
      default() {
        return false
      }
    },
    usesOpenHeaderFormButton: {
      type: Boolean,
      default() {
        return false
      }
    },
    evaluationClientList: {
      type: Array,
      default() {
        return []
      }
    },
    evaluationTypes: {
      type: Array,
      default() {
        return []
      }
    },
    evaluationIsLoading: {
      type: Boolean,
      default() {
        return true
      }
    },
    iconnameProp: {
      type: String,
      default() {
        return ''
      }
    },
    titleProp: {
      type: String,
      default() {
        return ''
      }
    },
    backlinkProp: {
      type: String,
      default() {
        return ''
      }
    },
    clientUuid: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      isMobile:false,
      userIconCount: 0
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/getUserName',
      userImage: 'auth/getUserImage',
      rolePermissions: 'auth/getRolePermissions'
    }),
  
    showTestBanner() {
      return false
    }
  },
  mounted() {
    this.setDeviceType()
    this.$store.dispatch('auth/refreshUserDataFromOffline')
  },
  methods: {
       add3Dots(userName, limit)
      {

        if(userName !== undefined && userName.length !== 0)
        {
          const dots = "...";
          if(userName.length > limit)
          {
            // you can also use substr instead of substring
            userName = userName.substring(0,limit) + dots;
          }

          return userName;
        }        
        
        return "";
      },
        setDeviceType() {
        //  const platform = navigator.platform.toLowerCase();
        
        const userAgent = navigator.userAgent
        const platform = navigator.userAgentData.platform.toLowerCase();
        
        if(/mobile/i.test(userAgent) || /Android/i.test(userAgent) || /webOS/i.test(userAgent) || /iPhone/i.test(userAgent || navigator.userAgentData.vendor) || /iPad/i.test(userAgent || navigator.userAgentData.vendor) || /iPod/i.test(userAgent || navigator.userAgentData.vendor) || /BlackBerry/i.test(userAgent) || /Windows Phone/i.test(userAgent))
        {
          this.isMobile = true
        }
        else
        if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
              this.isMobile = true
          } else if (/mac|win|linux/i.test(platform)) {
              this.isMobile = false
          } else if (/tablet|ipad/i.test(platform)) {
              this.isMobile = true
          } else {
              this.isMobile = false
          }
        
        //  console.log(userAgent);        
        //  console.log(this.isMobile);
        //  console.log(platform);
        
        
    },
    async logoutMethod() {
      // loutout
      await this.$store.dispatch('auth/logout')

      // redirect to login page
      // will only get here if logged out :)
      this.$router.push({ path: '/' })
    },
    setHeaderFormOpen() {
      this.$emit('set-header-form-open')
    },
    setHeaderFormClosed() {
      this.$emit('set-header-form-closed')
    }
  }
}
</script>

<style lang="scss">
.l-header {
  background: $col1;

  .test-banner {
    background-image: linear-gradient(
      45deg,
      #1f8f77 27.78%,
      #48b19a 27.78%,
      #48b19a 50%,
      #1f8f77 50%,
      #1f8f77 77.78%,
      #48b19a 77.78%,
      #48b19a 100%
    );
    background-size: 63.64px 63.64px;
    color: #fff;
    padding: 5px 0 0;
    text-align: center;
  }
  .button--open-basic {
    cursor: pointer;
    display: inline-block;
    padding: 0 5px;
  }

  .b-wrapper > & {
    position: fixed;
    z-index: z-index('two');
    top: 0;
    left: 0;
    width: 100%;
  }

  .b-wrapper:not(.b-wrapper--small-header):not(.b-wrapper--large-header) & {
    min-height: px-to-rem(260px);
  }
}

.l-header__container {
  margin: 0 auto;
  width: calc(100% - 30px);
  max-width: px-to-rem($max-width-container);

  @media (max-width: 768px) {
    display: flex;
  }

  @media (min-width: 1000px) {
    max-width: 1150px;
  }
}

.l-header__inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: px-to-rem(12px);
  padding: px-to-rem(18px) 0 0;

  @media (max-width: 768px) {
    display: block;
  }
}

.l-header__inner-container--top {
  min-height: px-to-rem(63px); // 45 height + 18 padding-top
  order: 2;
  @media (max-width: 768px) {
    width: 25%;
  }
}

.l-header__inner-container--bottom {
  align-items: flex-start;
  padding-top: px-to-rem(32px);
  order: 1;
  @media (max-width: 768px) {
    width: 75%;
  }
}

.l-header__right {
  position: relative;
  z-index: z-index('default');
  width: px-to-rem(288px);
  margin: 0 0 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.l-header__right--button-container {
  padding-top: px-to-rem(63px);
}

.l-header__logo {
  &,
  &:visited {
    opacity: 1;
  }

  &:active,
  &:hover,
  &:focus {
    opacity: 0.5;
  }
}

.l-header__nav {
  width: px-to-rem(270px);
  margin: px-to-rem(5px) 0 0 auto;

  @media (max-width: 768px) {
    width: 7rem;
    /*margin-left: 0;*/

    .b-nav__list {
      /*display: inline-block;*/
      /*min-width: 150px;*/
    }

    .b-nav--space-between .b-nav__list {
      justify-content: right;
    }

    .b-nav__list-element {
      /*display: inline-block;*/
    }
  }
  .b-nav__list {
    align-items: center;
  }

  .b-nav__link {
    &,
    &:visited {
      @extend %font-size--medium;
      color: $white-colour;
      font-weight: $bold;
    }

    &:active,
    &:focus,
    &:hover {
      color: $white-colour;
    }
  }
}

// .l-header__nav-user { }

.l-header__nav-user-image {
  position: relative;
  z-index: z-index('default');
  display: inline-block;
  vertical-align: middle;
  height: px-to-rem(45px);
  width: px-to-rem(45px);
  margin-right: px-to-rem(11px);
}

.l-header__nav-user-image-icon {
  position: absolute;
  z-index: z-index('one');
  border: px-to-rem(2px) solid $col1;
  background: $white-colour;
  height: px-to-rem(27px);
  width: px-to-rem(27px);
  padding: px-to-rem(4px) px-to-rem(3px) px-to-rem(3px);
  border-radius: 50%;
  overflow: hidden;
  top: px-to-rem(-9.5px);
  right: px-to-rem(-15.5px);

  .fill {
    fill: $col1;
  }
}

.l-header__nav-user-image-lazyload-image {
  position: relative;
  z-index: z-index('default');
  display: block;
  border-radius: 50%;
  overflow: hidden;
  height: px-to-rem(45px);
  width: px-to-rem(45px);
}

.l-header__nav-user-text {
  display: inline;
  vertical-align: middle;
}

.l-header__nav-logout {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.l-header__nav-logout-icon {
  margin-left: px-to-rem(15px);
}

.l-header__mobile-hidden {
  @media (max-width: 768px) {
    display: none;
  }
}

.l-header__title-icon {
  margin: 0 0 px-to-rem(16px);
}

.l-header__title-title {
  position: relative;
  z-index: z-index('default');
  @extend %font-size--large;
  color: $white-colour;
}

.l-header__title-title_mobile {
  position: relative !important;
  z-index: z-index('default') !important;
  @extend %font-size--large;
  color: $white-colour !important;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.l-header__title-title-backlink {
  @media (min-width: 1240px) {
    position: absolute;
    z-index: z-index('default');
    top: 50%;
    left: px-to-rem(-35px);
    transform: translateY(-50%);
  }
}

.l-header__button {
  display: flex;
  width: 100%;
  text-align: left;
  padding-left: px-to-rem(11px);
}

.l-header__button-icon {
  .b-button--icon--right & {
    margin: 0 0 0 auto;
  }
}
</style>
