<template>
  <figure
    v-if="lazyloadProp"
    class="b-lazyload-container"
    :class="containerClassProp"
    :style="paddingTop"
  >
    <img
      :id="idProp"
      ref="bLazyload"
      class="b-image u-no-margin b-lazyload"
      :class="classProp"
      :data-src="srcProp"
      :data-srcset="srcSetProp"
      :data-sizes="sizesProp"
      :alt="altProp"
      draggable="false"
    />
  </figure>

  <img
    v-else
    :id="idProp"
    class="b-image"
    :class="classProp"
    :src="srcProp"
    :srcset="srcSetProp"
    :sizes="sizesProp"
    :alt="altProp"
    draggable="false"
  />
</template>

<script>
import LazyLoadInit from '~/plugins/vanilla-lazyload.js'

export default {
  props: {
    lazyloadProp: {
      type: Boolean,
      default() {
        return true
      }
    },
    idProp: {
      type: String,
      default() {
        return null
      }
    },
    srcProp: {
      type: String,
      default() {
        return null
      }
    },
    srcSetProp: {
      type: String,
      default() {
        return null
      }
    },
    sizesProp: {
      type: String,
      default() {
        return null
      }
    },
    altProp: {
      type: String,
      default() {
        return null
      }
    },
    widthProp: {
      type: Number,
      default() {
        return 500
      }
    },
    heightProp: {
      type: Number,
      default() {
        return 500
      }
    },
    containerClassProp: {
      type: String,
      default() {
        return null
      }
    },
    classProp: {
      type: String,
      default() {
        return null
      }
    }
  },
  computed: {
    paddingTop() {
      const PADDING_TOP = (this.heightProp / this.widthProp) * 100

      return 'padding-top: ' + PADDING_TOP.toString() + '%;'
    }
  },
  mounted() {
    if (process.browser) {
      // if object fit doesnt exist, then make the image the background of the parent
      if ('objectFit' in document.documentElement.style === false) {
        const EL_IMAGE = this.$el.querySelector('.b-lazyload')

        if (EL_IMAGE) {
          const EL_PARENT = EL_IMAGE.parentNode
          const EL_DATASET = EL_IMAGE.dataset
          const EL_DATASET_SRC = EL_IMAGE.dataset.src

          // remove src and srcset
          EL_IMAGE.src = ''
          EL_DATASET.src = ''
          EL_DATASET.srcset = ''

          // remove js initialisation, make image the background
          EL_PARENT.classList.add('b-lazyload-container--bg')

          // make image background
          EL_PARENT.style.backgroundImage = "url('" + EL_DATASET_SRC + "')"
        }
      } else {
        LazyLoadInit.init()
      }
    }
  }
}
</script>

<style lang="scss">
.b-lazyload-container {
  display: block;
  position: relative;
  z-index: z-index('default');
  height: 0;
  overflow: hidden;
  background: $col2;
  width: 100%;

  &--bg {
    background-size: cover;
    background-position: center center;
  }
}

.b-lazyload {
  display: block;
  position: absolute;
  z-index: z-index('one');
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 250ms opacity ease-in-out;
  object-fit: cover;
  overflow: hidden;
  margin: 0;
  text-indent: px-to-rem(-5000000000px);
}

.b-lazyload--loaded {
  opacity: 1;
}
</style>
