<template>
  <div class="b-icon-indicator" :class="mainClassProp.length > 0 ? mainClassProp : null">
    <div
      class="b-icon-indicator__indicator"
      :class="indicatorClassProp.length > 0 ? indicatorClassProp : null"
    >
      <slot>
        <icon-base
          class="b-link__icon b-icon-indicator__indicator-icon"
          :icon-details="indicatorIconObjectProp"
        />
      </slot>
    </div>

    <icon-base class="b-link__icon b-icon-indicator__icon" :icon-details="iconObjectProp" />
  </div>
</template>

<script>
import IconBase from '~/components/_base/Icon'

export default {
  layout: ({isMobile})=> isMobile ? 'mobile' : 'default',
  components: {
    IconBase
  },
  props: {
    mainClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    indicatorClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    indicatorIconObjectProp: {
      type: Object,
      default() {
        return {
          name: 'CrossTwo',
          size: '25',
          color: '',
          class: ''
        }
      }
    },
    iconObjectProp: {
      type: Object,
      default() {
        return {
          name: 'CrossTwo',
          size: '30',
          color: '',
          class: ''
        }
      }
    }
  }
}
</script>

<style lang="scss">
.b-icon-indicator {
  position: relative;
  
  z-index: z-index('default');
}

.b-icon-indicator__indicator {
  @extend %font-size--small;
  height: px-to-rem(25px);
  width: px-to-rem(25px);
  line-height: px-to-rem(25px);
  color: $white-colour;
  text-align: center;
  display: block;
  position: absolute;
  z-index: z-index('one');
  top: 0;
  right: 0;
  box-shadow: 0 px-to-rem(2px) px-to-rem(4px) 0 rgba($black-colour, 0.5);
  border-radius: 50%;
  transition: opacity 250ms ease-in-out;
}

.b-icon-indicator__indicator--hidden {
  opacity: 0;
}

.b-icon-indicator__icon {
  position: relative;
  z-index: z-index('default');
  margin-left: padding('mobile');
}
</style>

